body {
  margin: 0;
}

#sidenav {
  margin: 0;
  padding: 0 20px;
  /* width: 150px; */
  min-width: 150px;
  height: 100vh;
  overflow: auto;
  border-radius: 0;
  background-color: #000000;
  color: #ffffff;
  flex-direction: column;
  display: flex;
}

main {
  overflow-y: auto;
  padding: 40px;
  margin: auto;
  min-width: 600px;
  max-width: 1200;
  margin-left: 300;
  min-height: 100vh;
  overflow: auto;
  margin-left: 250px;
}

.SMSPageContainer {
  max-width: 800px;
  margin: auto;
}

.modalBase {
  padding: 30px;
}

.smsGroupSelection {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.navlinkitembottom {
  margin-top: auto;
}

#sidenav a,
#sidenav a:hover,
#sidenav a:visited {
  color: #ffffff;
  font-style: none;
  text-decoration: none;
}

.navlinkitem {
  display: flex;
  gap: 14px;
  margin-bottom: 20px;
}

.navlinkitem:hover {
  color: #82b6fa;
  font-weight: 600;
  cursor: pointer;
}

#logo {
  display: block;
  width: 120px;
  margin-bottom: 30px;
}

.messagedetails-message {
  font-style: italic;
  border-left: 2px solid #a9a9a9;
  padding: 10px 0 10px 20px;
  max-width: 500px;
  text-wrap: wrap;
  white-space: pre-wrap;
}

#rdosidenav {
  position: fixed; /* Fix the position of the sidebar */
}

/* Media Query for screens smaller than 600px */
@media (max-width: 900px) {
  main {
    padding: 20px; /* Reduce padding for small screens. */
    max-width: 100%; /* Allow the main element to use the full width on small screens. */
    min-width: 0; /* Override the min-width to prevent horizontal scrolling on small devices. */
    margin: 50px 0 0 0;
  }
  #sidenav {
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
  }
  #sidenav a {
    text-align: center;
    float: none;
  }
  #logo {
    display: none;
  }

  .navlinkitem {
    font-size: large;
    margin-bottom: 40px;
  }
}
